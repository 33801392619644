import React from 'react';

const Footer = ({ form = true }) => (
  <footer id="footer">
    <div className="inner">
      {form ? (
        <>
          <ul className="contact">
            <li className="icon solid fa-envelope">
              <strong>Email</strong>
              <a href="mailto:danielle.m.abril@gmail.com">
                danielle.m.abril@gmail.com
              </a>
            </li>

            <li className="icon brands fa-twitter">
              <strong>Twitter</strong>
              <a href="https://twitter.com/DanielleDigest">
                https://twitter.com/DanielleDigest
              </a>
            </li>

            <li className="icon brands fa-linkedin">
              <strong>LinkedIn</strong>
              <a href="https://www.linkedin.com/in/danielle-abril-964949a">
                https://www.linkedin.com/in/danielle-abril-964949a
              </a>
            </li>
          </ul>

          <form
            name="contact"
            netlify-honeypot="bot-field"
            action="/thank-you"
            method="post"
            data-netlify="true"
          >
            <input style={{ display: 'none' }} name="bot-field" />
            <h4>Get in touch</h4>
            <div className="fields">
              <div className="field half">
                <label htmlFor="name">Name</label>
                <input name="name" id="name" type="text" placeholder="Name" />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  rows="6"
                  placeholder="Message"
                />
              </div>
            </div>
            <ul className="actions">
              <li>
                <input
                  value="Send Message"
                  className="button large"
                  type="submit"
                />
              </li>
            </ul>
          </form>
        </>
      ) : null}
    </div>
  </footer>
);

export default Footer;
