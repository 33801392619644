import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ siteTitle }) => (
  <>
    <header id="header">
      <span className="logo">
        <Link to="/">{siteTitle}</Link>
      </span>

      <Link to="/clips">Clips</Link>
      <Link to="/resume">Résumé</Link>
    </header>
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

export default Header;
